import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userLogin } from "../../actions/user";
import { loginSuccess } from '../../../redux/actions/auth'
import { logout } from '../../../redux/actions/auth'
import email from '../../public/images/email.png';
import logo from '../../public/images/logo.png';
import password from '../../public/images/password.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;

const Login = (props) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    //onchange input field
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    //password field show or hide
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    //validation form on email password 
    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!formData.email) {
            formIsValid = false;
            errors = { ...errors, email: "Please enter your email." };
        }
        else if (typeof formData.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email.trim())) {
                formIsValid = false;
                errors = { ...errors, email: "Please enter valid email." };
            }
        }

        if (!formData.password) {
            formIsValid = false;
            errors = { ...errors, password: "Please enter your password." };
        }

        setErrorMessages(errors);
        return formIsValid;
    }

    //form submit on login 
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.email) {
            toast.error("Please enter email address");
            return;
        }
        else if (formData.email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formData.email)) {
            toast.error("Please enter valid email address");
            return;
        }
        else if (!formData.password) {
            toast.error("Please enter password");
            return;
        }

        if (validateForm()) {
            setLoading(true);

            let params = {
                email: formData.email.trim(),
                password: formData.password
            }
            props.userLogin(params, res => {
                setLoading(false);
                if (res.status) {
                    let userData = {
                        userProfile: {
                            email: res.data.email,
                            is_active: res.data.is_active,
                            id: res.data.id
                        },
                        token: res.data.token
                    }
                    props.onLoginSuccess(userData);
                    history.push('/');
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        }

    };

    useEffect(() => {
        document.title = "login";
        props.logout();
    }, []);

    return (
        <>
            {/* <!-- Start Main --> */}
            <section className="adminLogin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 p-0">
                            <div className="loginContent pt-5 ps-3">
                                <div onClick={() => { history.push('/login') }} className="logo">
                                    <img className="img-responsive" src={logo} />
                                </div>
                                <div className="loginForm mt-5">
                                    <form onSubmit={(e) => { handleSubmit(e) }} className="loginHorizontalForm_">
                                        <h3 className="mb-2">Log In</h3>
                                        <p className="mb-5">Enter the below details for login:</p>
                                        <div className="groupInputs_ mb-4">
                                            <label className="loginIcon">
                                                <img src={email} />
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                placeholder='Email'
                                                value={formData.email}
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                        </div>
                                        <div className=" groupInputs_  mb-4">
                                            <label className="loginIcon">
                                                <img src={password} />
                                            </label>
                                            <input
                                                className="form-control id_password"
                                                placeholder="Password"
                                                name="password"
                                                type={passwordShown ? "text" : "password"}
                                                value={formData.password}
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                            {/* <span className="toggle_password_">
                                                <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
                                            </span> */}
                                            <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblity(!passwordShown) }}>
                                                <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                            </span>
                                        </div>
                                        <div className="forget-link mb-4">
                                            <a onClick={() => { history.push('/forgotPassword') }}>Forgot Password?</a>
                                        </div>
                                        <button className="btnTheme" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Login</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 p-0">
                            <div className="loginImage">
                                <p className="innerContent">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it
                                    look like readable English.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Main --> */}
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    userLogin: bindActionCreators(userLogin, dispatch),
    onLoginSuccess: payload => dispatch(loginSuccess(payload)),
    logout: bindActionCreators(logout, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);