import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadIconImage from "../../public/images/upload-icon.svg";
import groupImage from "../../public/images/Group 10825.svg";
import { getAllSettings, getsettings, uploadPhoto } from "../../actions/settings";
import { useHistory } from "react-router-dom";

const AddSettings = (props) => {
    const history = useHistory();
    const [allSettings, setAllSettings] = useState("");
    const [fullname, setFullname] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [privacy, setPrivacy] = useState("");
    const [termsCondition, setTermsCondition] = useState("");
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState("");

    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "fullname") {
            setFullname(value)
        }
    };

    // //onChange fullname with only enter alpha
    // const handleChange = (e) => {
    //     const { value } = e.target;
    //     const re = /^[A-Za-z]+$/;
    //     if (value === "" || re.test(value)) {
    //         setFullname(value);
    //     }
    // }

    //get all settings Data
    const getSettings = () => {
        let params = {
            token: props.auth.token,
        }
        setLoading(true);
        props.getAllSettings(params, (res) => {
            setLoading(false);
            if (res.statusCode == 200 || res.statusCode == 201) {
                setFullname(res.data.name)
                setDescription(res.data.description)
                setPrivacy(res.data.privacy)
                setTermsCondition(res.data.tearms_condition)
                setImage(res.data.logo)

            }
            else {

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }


    //onChange logo
    const onChangePicture = e => {
        const imageFile = e.target.files[0];
        if (!imageFile) {
            toast.error("Please select an image.");
            return false;
        }
        else if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }

        let params = {
            logo: imageFile,
            token: props.auth.token,
        }
        setIsLoadingImg(true);
        props.uploadPhoto(params, res => {
            setIsLoadingImg(false);
            if (res.status) {
                let picUrl = res.data.setting_logo;
                setImage(picUrl);
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoadingImg(false);
                toast.error("Something went wrong!");
            });

    };

    //form submit
    const handleSubmit = () => {
        if (!fullname) {
            toast.error("Please enter full name");
            return;
        }
        else if (fullname.length <= 2) {
            toast.error("Name should be maximum 3 character")
            return;
        }
        else if (!image) {
            toast.error("Please select logo ");
            return;
        }
        else if (!description) {
            toast.error("Please enter description")
            return;
        }
        else if (!privacy) {
            toast.error("Please enter privacy")
            return;
        }
        else if (!termsCondition) {
            toast.error("Please enter terms & condition")
            return;
        }

        let params = {
            token: props.auth.token,
            name: fullname,
            description: description,
            privacy: privacy,
            tearms_condition: termsCondition,
            logo: image

        }

        setIsLoading(true);
        props.settings(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                getSettings();
                history.push("/")

            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })

    };
    
    useEffect(() => {
        document.title = "settings";
        getSettings();
    }, [])


    return (
    <div className="content-wrapper">
        <div className="contentMain">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-lg-12 col-xl-12 col-xxl-5">
                            <div id="accountDetails">
                                <div className="card-body p-0">
                                    <form className="form_horizontal row" role="form" >
                                        <div className="form-group mt-4 mb-4 col-md-12">
                                            <label className="label_text" htmlFor="inputEmailAddress">Name</label>
                                            <input
                                                min={2}
                                                maxLength={50}
                                                className='form-control'
                                                placeholder="Enter Full name"
                                                type="text"
                                                id="inputName"
                                                value={fullname}
                                                name="name"
                                                onChange={(e) => { onChangeInput(e, 'fullname') }}
                                            />
                                        </div>
                                        <div className="profile_upload">
                                            <label className="label_text" htmlFor="inputEmailAddress">Logo</label>
                                            {isLoadingImg ?
                                                <div className="spinner-border text-success mt-3 loader_" role="status" >
                                                    <span className="sr-only" >Loading...</span>
                                                </div>
                                                :
                                                <div className="inputfile-box">
                                                    <input type="file" id="file" accept="image/*" className="form-control d-none" onChange={(e) => { onChangePicture(e) }} required="" />
                                                    <label htmlFor="file">
                                                        <span className="file-button">
                                                            <img src={image ? image : groupImage} style={{ width: 100, height: 100 }} className="upload_src" alt=' ' />
                                                            <img src={UploadIconImage} className="upload_icon" alt=' ' />
                                                        </span>
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group mt-2 mb-2 col-md-12">
                                            <label className="label_text" htmlFor="inputPassword">Description</label>
                                            <CKEditor
                                                name="content"
                                                id="inputName"
                                                className="ckp"
                                                editor={ClassicEditor}
                                                config={{
                                                    removePlugins: ['MediaEmbed'],
                                                 
                                                  }}
                                                data={description  ? description : ""}
                                                onReady={(editor) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log("Editor is ready to use!", editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData()
                                                    setDescription(data)
                                                    // console.log({ event, editor, data }, 'editor');
                                                }

                                                }
                                            /> 
                                        </div>
                                        <div className="form-group mt-2 mb-2 col-md-12">
                                            <label className="label_text" htmlFor="inputPassword">Privacy</label>
                                            <CKEditor
                                                name="content"
                                                id="inputName"
                                                className="ckp"
                                                editor={ClassicEditor}
                                                config={{
                                                    removePlugins: ['MediaEmbed']
                                                  }}
                                                data={privacy ? privacy : ""}
                                                onReady={(editor) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log("Editor is ready to use!", editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData()
                                                    setPrivacy(data)
                                                    // console.log({ event, editor, data }, 'editor');
                                                }

                                                }
                                            />
                                        </div>
                                        <div className="form-group mt-2 mb-2 col-md-12">
                                            <label className="label_text" htmlFor="inputPassword">Terms-Condition</label>
                                            <CKEditor
                                                name="content"
                                                id="inputName"
                                                className="ckp"
                                                editor={ClassicEditor}
                                                config={{
                                                    removePlugins: ['MediaEmbed']
                                                  }}
                                                data={termsCondition ? termsCondition : ""}
                                                onReady={(editor) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log("Editor is ready to use!", editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData()
                                                    setTermsCondition(data)
                                                    // console.log({ event, editor, data }, 'editor');
                                                }

                                                }
                                            />
                                        </div>
                                        <div className="form-group text-center col-md-12 mt-3 mb-3">
                                            &nbsp;&nbsp;&nbsp;
                                            <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleSubmit() }}>
                                                {isLoading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                &nbsp;&nbsp;
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div >
                    </div >
                </div>
            </div>
        </div>
    </div>
         
    )
}    

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    uploadPhoto: bindActionCreators(uploadPhoto, dispatch),
    settings: bindActionCreators(getsettings, dispatch),
    getAllSettings: bindActionCreators(getAllSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSettings);