import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { addNewUser } from "../../actions/user";

const AddUser = (props) => {
    const [userEmail, setUserEmail] = useState("");
    const [fullname, setFullname] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "fullname") {
            // setFullname(value.replace(/[^a-z]/gi, ''))
            setFullname(value)
        }
        else if (fieldName === "email") {
            setUserEmail(value.trim());
        }
    };

    // //onChange fullname with only enter alpha
    // const handleChange = (e) => {
    //     const { value } = e.target;
    //     const re = /^[A-Za-z]+$/;
    //     if (value === "" || re.test(value)) {
    //         setFullname(value);
    //     }
    // }

    //add new user
    const handleSubmit = () => {
        if (!fullname) {
            toast.error("Please enter full name");
            return;
        }
        else if (fullname.length <= 2) {
            toast.error("Name should be maximum 3 character")
            return;
        }
        // else if (!/^(?=.{1,20}$)[a-zA-Z]+$/g.test(fullname)) {
        //     toast.error("Full name numbers are not allowed");
        //     return;
        // }
        else if (!userEmail) {
            toast.error("Please enter email");
            return;
        }
        else if (userEmail && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userEmail)) {
            toast.error("Please enter valid email address");
            return;
        }

        let params = {
            token: props.auth.token,
            email: userEmail,
            fullname: fullname,
            role_id: 2
        }

        setIsLoading(true);
        props.addNewUser(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshgetUsers();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })

    };

    useEffect(() => {
    
    }, [])


    return (
        <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
            <div className="form-group mt-3 mb-0 col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Full Name</label>
                <input
                    min={2}
                    maxLength={30}
                    className='form-control'
                    placeholder="Enter Full name"
                    type="text"
                    id="inputName"
                    value={fullname}
                    name="name"
                    onChange={(e) => { onChangeInput(e, 'fullname') }}
                />
            </div>
            <div className="form-group mt-3 mb-2 col-md-12">
                <label className="label_text" htmlFor="email">Email</label>
                <input className="form-control" id="email" type="text" value={userEmail} placeholder="Enter email"
                    name="email" required="" onChange={(e) => { onChangeInput(e, 'email') }} />
            </div>

            <div className="form-group  col-md-12 mt-3 mb-3">
                <button className="btn btn-danger" type="button" onClick={props.closeModal}>
                    <span>Close</span>
                </button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleSubmit() }}>
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Submit</span>
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    addNewUser: bindActionCreators(addNewUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);