import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import {AUTH_API_URL } from "../../config";

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {
  let responseMessage = defaultErrorMessage;
  let payload = response.payload;  // Data returned by API

  if (payload && Array.isArray(payload) && payload.length > 0) {
    responseMessage = payload[0];
  }
  else if (response.msg) {
    responseMessage = response.msg;
  }
  return responseMessage;
}

const getApiResponse = (result, responseMessage) => {
  let res = {};
  if (result.status === 200 || result.status === 201) {
    res = {
      status: true,
      message: responseMessage,
      statusCode: result.status,
      type: message.success,
      data: result.data.payload
    };
  } else {
    res = {
      status: false,
      message: responseMessage,
      type: message.error,
      statusCode: result.status
    };
  }
  return res;
}


/****** action creator for upload user logo ********/
export const uploadPhoto = (params, cb) => {
  var formData = new FormData();
  formData.append('logo', params.logo);
  return dispatch => {
    RestClient.postFormData(`${AUTH_API_URL}/settings/logo`, formData)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to upload profile picture! Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator for settings ********/
export const getsettings = (params, cb) => {
  return dispatch => {
    RestClient.post(`${AUTH_API_URL}/settings`, params)
      .then(result => {
        let response = result.data;  // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator for get All settings data ********/
export const getAllSettings = (params, cb) => {
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/settings/get-one`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};
   

/****** action creator for change password ********/
export const changePassword = (params, cb) => {
  return dispatch => {
      RestClient.post(`${AUTH_API_URL}/users/change-password`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to process request. Please try again later.');   
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};
