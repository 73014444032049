import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import moment from 'moment'
import ViewNotes from '../notes/view';
import EditNotes from '../notes/edit';
import AddNotes from '../notes/add';
import Swal from 'sweetalert2';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import PaginationSetting from '../../constants/pagination';
import { deleteNotes, getAllNotes, getNotesChangeStatus } from '../../actions/notes';

var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();

const Notes = (props) => {
    const [pagination, setPagination] = useState(PaginationSetting)
    const [notes, setNotes] = useState([])
    const [selectedNoteId, setselectedNoteId] = useState([])
    const [selectedEditNote, setSelectedEditNote] = useState([])
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [checked, setChecked] = useState(true);
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    //get all notes
    const getNotes = (clearSearch = false, active) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
            filter: active
        }

        if (!clearSearch && searchInput && searchInput != '' || active) {
            params = { ...params, filter: active, search: searchInput.trim(), page: 1, };
        }

        setLoading(true);
        props.getAllNotes(params, (res) => {
            setLoading(false);
            if (res.statusCode == 200 || res.statusCode == 201) {
                setNotes(res.data.notes_data)

                setPagination((prevState) => ({ ...prevState, totalPages: res.data.total_pages, totalRecords: res.data.total }));

            }
            else {
                setNotes([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    //get status change by Id
    const getChangeStatus = (id) => {
        Swal.fire({
            title: 'Are you sure want to change status?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    token: props.auth.token,
                    id: id
                }
                setLoading(true);
                props.getNotesChangeStatus(params, (res) => {
                    setLoading(false);
                    if (res.statusCode == 200 || res.statusCode == 201) {
                        getNotes();
                    }
                    else {
                        if (res.statusCode !== 404) {
                            toast.error(res.message);
                        }
                    }
                },
                    (err) => {
                        setLoading(false);
                        toast.error(err.message);
                    })
            }
            else if (result.isDenied) {
                // 
            }
        })
        // let params = {
        //     token: props.auth.token,
        //     id: id
        // }
        // setLoading(true);
        // props.getNotesChangeStatus(params, (res) => {
        //     setLoading(false);
        //     if (res.statusCode == 200 || res.statusCode == 201) {
        //         getNotes();
        //     }
        //     else {
        //         if (res.statusCode !== 404) {
        //             toast.error(res.message);
        //         }
        //     }
        // },
        //     (err) => {
        //         setLoading(false);
        //         toast.error(err.message);
        //     })
    }


    //close modal
    const handleOnCloseModal = () => {
        setModalDetail({ show: false, title: '', flag: "" });
        setKey(Math.random());
    }

    //get id of selected view note
    const viewNoteDetail = (userId) => {
        setselectedNoteId(userId);
        setTimeout(() => {
            setModalDetail({ show: true, title: 'Note Details', flag: "view" });
            setKey(Math.random());
        }, 200);
    }


    //get id of selected note
    const editNoteDetails = (userId) => {
        let selectedEditNote = notes.find(item => item.id == userId);
        setSelectedEditNote(selectedEditNote);
        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit Note' });
            setKey(Math.random());
        }, 200);
    }

    //search input
    const onChangeSearchInput = (searchValue) => {
        setSearchInput(searchValue);
    }

    //onclick active/deactive user 
    const handleClick = (event) => {
        let active = event.target.value;
        getNotes(event, active)
    }

    //change status active/inactive note
    const handleChange = (event, value) => {
        setChecked(event.target.checked);
        getChangeStatus(value)
    };

    // delete note
    const deleteNote = (noteId) => {
        Swal.fire({
            title: 'Are you sure want to remove this note?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    id: noteId,
                    token: props.auth.token
                }
                props.deleteNotes(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getNotes();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };

    useEffect(() => {
        document.title = "note";
        getNotes();
    }, [page])

    return (
        <>
            <div className="content-wrapper">
                <div class="contentMain">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12 col-md-6">
                                    <h1 className="m-0">Notes &nbsp;&nbsp;
                                        <button type="button" className="btn btn-primary addBtn"
                                            onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New Note' }); setKey(Math.random()); }}
                                        >
                                            <i className="fas fa-plus"></i> Add </button> &nbsp; &nbsp;
                                        {/* <button onClick={handleClick} value="Active" type="button" className="btn btn-primary addBtn">
                                            <i className="fas fa-user-alt"></i> Active </button> &nbsp; &nbsp;
                                        <button onClick={handleClick} value="UnActive"  type="button" className="btn btn-primary addBtn">
                                            <i className="fas fa-user-slash"></i> Unactive </button> */}
                                    </h1>
                                </div>
                                <div className="col-sm-12 col-md-6 text-end">
                                    <form className='inputSearchForm'>
                                        <div className='input-group me-2'>
                                            <input type="text" className="form-control rounded" onChange={(e) => onChangeSearchInput(e.target.value)} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                            <button className='inputClose' onClick={() => { onChangeSearchInput(""); getNotes(true) }} type="reset"> <i className="las la-times"></i> </button>
                                        </div>
                                        <button className='searchBtn' type="button" onClick={() => { return searchInput ? getNotes() : '' }}>Search</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Main content */}
                    <section className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <div className="row p-5 mt-5 mb-5">
                                                <div className="col-12 text-center">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className='table-responsive'>
                                                    <table style={{ width: '100%' }} className="table table-striped">
                                                        <thead style={{ fontSize: '15px' }}>
                                                            <tr>
                                                                <th style={{ borderTop: '0px' }} className='text-center'>Sr. No.</th>
                                                                <th style={{ borderTop: '0px' }}>Note</th>
                                                                <th style={{ borderTop: '0px' }}>Created At</th>
                                                                <th style={{ borderTop: '0px' }}>Status</th>
                                                                <th style={{ borderTop: '0px' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ fontSize: '15px' }}>
                                                            {notes && notes.length > 0 ?
                                                                <>
                                                                    {notes.map((value, index) =>
                                                                    (
                                                                        <tr key={index} >
                                                                            <td className='text-center'>{(page - 1) * pagination.recordsPerPage + index + 1}</td>
                                                                            <td> {(value && value.note && value.note.length > 50 ? htmlToReactParser.parse(value.note.substring(0, 50) + "...") : htmlToReactParser.parse(value.note))}</td>
                                                                            <td>{moment(value.created_at ? value.created_at : "--").format("YYYY/MM/DD")}</td>
                                                                            <td style={{ paddingTop: "4px" }}>
                                                                                {value.is_active === true ?
                                                                                    <>
                                                                                        Active
                                                                                        <Switch
                                                                                            checked={true}
                                                                                            className="react-switch"
                                                                                            onChange={(e) => handleChange(e, value.id)}
                                                                                        />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        Inactive
                                                                                        <Switch
                                                                                            checked={false}
                                                                                            onChange={(e) => handleChange(e, value.id)}
                                                                                            className="react-switch"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            </td> 
                                                                            <td>
                                                                                <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => { editNoteDetails(value.id) }}
                                                                                    style={{ background: '#17a2b8', color: 'white' }}>
                                                                                    <span>
                                                                                        <i className='fas fa-pencil-alt'></i>
                                                                                    </span>
                                                                                    Edit
                                                                                </button>
                                                                                <button
                                                                                    className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => { viewNoteDetail(value.id) }}
                                                                                    style={{ background: '#17a2b8', color: 'white' }}
                                                                                >
                                                                                    <span> <i className='fa fa-eye'></i></span>
                                                                                    View
                                                                                </button>
                                                                                <button className='btn deletebtn btn-del-key internal mr-2 btn-danger'
                                                                                    onClick={() => { deleteNote(value.id) }}
                                                                                >
                                                                                    <span>
                                                                                        <i className='fas fa-trash'></i>
                                                                                    </span>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )

                                                                    )
                                                                    }
                                                                </>
                                                                :
                                                                <tr>
                                                                    <td colSpan='7' style={{ textAlign: 'center' }}>No Data Found</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                                {pagination.totalRecords > pagination.recordsPerPage &&
                                                    <Box py={3} display="flex" justifyContent="center">
                                                        <Pagination
                                                            key={page}
                                                            count={pagination.totalPages ? pagination.totalPages : "" || 0}
                                                            color="secondary"
                                                            variant="outlined"
                                                            onChange={(e, value) => setPage(value)}
                                                            defaultPage={page}
                                                        />
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={modalDetail.flag == 'add'
                    ? <AddNotes closeModal={() => handleOnCloseModal()} selectedNoteId={selectedNoteId} refreshgetNotes={() => { getNotes() }} />
                    : modalDetail.flag == "edit"
                        ? <EditNotes closeModal={() => handleOnCloseModal()} selectedEditNote={selectedEditNote} refreshgetNotes={() => { getNotes() }} />
                        : <ViewNotes closeModal={() => handleOnCloseModal()} selectedNoteId={selectedNoteId} refreshgetNotes={() => { getNotes() }} />

                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllNotes: bindActionCreators(getAllNotes, dispatch),
    getNotesChangeStatus: bindActionCreators(getNotesChangeStatus, dispatch),
    deleteNotes: bindActionCreators(deleteNotes, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notes);