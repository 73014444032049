import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const AuthLayout = (props) => {
    const location = useLocation();
    const pathName = location.pathname;
    const authRoutes = ['/privacypolicy',];

    return (
        <>
            {/* <div className='login-box'> */}
            {authRoutes.includes(pathName) ?

                props.children :
                <div className='login-page'>
                    <div className="card card-outline card-primary">
                        {props.children}
                        <Footer />
                    </div>
                </div>
            }
            {/* </div> */}
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);