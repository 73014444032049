import React from 'react';
import { useHistory } from 'react-router-dom';
import BrandLogo from "../../public/images/logo.png";

const Sidebar = () => {
    const history = useHistory();
    const pathName = window.location.pathname;

    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a onClick={() => { history.push('/') }} className="brand-link">
                    {/* <span className="brand-text font-weight-bold">Shepherd</span> */}
                    <span className="first-letter img-circle elevation-3 ml-3">H</span>
                    <img src={BrandLogo} className="brand-text font-weight-light" alt="Brand Logo" style={{ width: "74px" }} />
                </a>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
                            <li className="nav-item">
                                <a onClick={() => { history.push('/') }} className={['/'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Dashboard</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/user') }} className={['/user'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-user-circle"></i>
                                    <p>User</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/questions') }} className={['/careRole'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-user-tie"></i>
                                    <p>Questions</p>
                                </a>
                            </li>
 
                            {/* This is note sidebar */}
                            <li className="nav-item">
                                <a onClick={() => { history.push('/notes') }} className={['/notes'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className=" nav-icon fas fa-address-book"></i>
                                    <p>Notes </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { history.push('/settings') }} className={['/settings'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-user-circle"></i>

                                    <p>Settings </p>
                                </a>
                            </li>

                            {/* <li className="nav-item">
                                <a onClick={() => { history.push('/medicalConditions') }} className={['/medicalConditions'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-user-circle"></i>
                                    <p>Medical Conditions</p>
                                </a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a onClick={() => { history.push('/lockBoxes') }} className={['/lockBoxes'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-user-circle"></i>
                                    <p>Document Types </p>
                                </a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a onClick={() => { history.push('/Resources') }} className={['/Resources'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-user-circle"></i>
                                    <p>Resources </p>
                                </a>
                            </li> */}



                            {/* <li className={['/reports', '/'].includes(pathName) ? "nav-item has-treeview menu-open selected" : "nav-item has-treeview"}>
                                <a href="/" className="nav-link">
                                    <i className="nav-icon fas fa-chart-pie" />
                                    <p>
                                        Reports
                                        <i className="right fas fa-angle-left" />
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item ml-3">
                                        <a className={pathName == '/' ? "nav-link active" : "nav-link" } onClick={() => {history.push('/')}}>
                                            <i className="nav-icon fas fa-tachometer-alt" />
                                            <p className=''>Dashboard</p>
                                        </a>
                                    </li>
                                    <li className="nav-item ml-3">
                                        <a className={pathName == '/reports' ? "nav-link active" : "nav-link" } onClick={() => {history.push('/reports')}}>
                                            <i className="nav-icon fas fa-chart-bar" />
                                            <p className=''>Reports</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>  */}
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    )
}

export default Sidebar