import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { addNewNote } from "../../actions/notes";
import { getAllQuestions } from "../../actions/allQuestionsAnswer";
import PaginationSetting from '../../constants/pagination';

const AddNotes = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState(PaginationSetting)
    const [allQuestions, setAllQuestions] = useState([]);
    const [optionId, setOptionId] = useState([]);
    const [note, setNote] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    const onChangeInput = (Id) => {
        let filesArr = [];
        filesArr.push(Id);
        setOptionId(prevState => [...prevState, ...filesArr]);
    };

    // get all queations
    const getAllQuestions = () => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }
        setLoading(true)
        props.getAllQuestions(params, (res) => {
            setLoading(false);
            if (res.status) {
                let questions = res.data;
                setAllQuestions(questions);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.total_pages, totalRecords: res.data.total }));
            }
            else {
                setAllQuestions([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }


    //add new note
    const handleSubmit = () => {
        if (!optionId[0] || !optionId[1] || !optionId[2]) {
            toast.error("Please choose all questions options");
            return;
        }
        else if (!note) {
            toast.error("Please enter note");
            return;
        }
        let params = {
            token: props.auth.token,
            combination_json: [
                {
                    option_id: optionId[0],
                    question_id: allQuestions[0].id
                },
                {
                    option_id: optionId[1],
                    question_id: allQuestions[1].id
                },
                {
                    option_id: optionId[2],
                    question_id: allQuestions[2].id
                }
            ],
            note: note
        }

        setIsLoading(true);
        props.addNewNote(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshgetNotes();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })

    };


    useEffect(() => {
        getAllQuestions();
    }, [])


    return (
        <form className="form_horizontal addNewform row ps-3 pe-3" role="form" method="POST" action="" >
            <div className="form-group mt-3 mb-0 col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Select answers for below question</label>
                {allQuestions && allQuestions.length > 0 && allQuestions.map((question, indexs) => {
                    return <>
                        <div className="newnotes">
                            <p value={question.id} key={indexs}>{question.title}</p>
                            {
                                question.question_options.map((options, index) => {
                                    return <>
                                        <label>
                                            <input
                                                type="radio"
                                                key={index}
                                                value={options.title}
                                                name={"question_" + question.id}
                                                onChange={(e) => onChangeInput(options.id)}
                                            />  {options.title}
                                            &nbsp;&nbsp;
                                        </label>
                                    </>
                                })
                            }
                        </div>
                    </>

                })}
            </div>
            <div className="form-group mt-2 mb-2 col-md-12">
                <label className="label_text" htmlFor="inputPassword">Note</label>
                <CKEditor
                    name="content"
                    id="inputName"
                    className="ckp"
                    editor={ClassicEditor}
                    // config={{
                    //     removePlugins: ['MediaEmbed'],

                    // }}
                    config={{
                        removePlugins: [ "EasyImage","ImageUpload","MediaEmbed", "Table" ]
                      }}
                    data={note}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData()
                        setNote(data)
                        // console.log({ event, editor, data }, 'editor');
                    }
                    }
                />
            </div>
            <div className="form-group  col-md-12 mt-3 mb-3">
                <button className="btn btn-danger" type="button" onClick={props.closeModal}>
                    <span>Close</span>
                </button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleSubmit() }}>
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Submit</span>
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    addNewNote: bindActionCreators(addNewNote, dispatch),
    getAllQuestions: bindActionCreators(getAllQuestions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNotes);