import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDashboardData } from "../../actions/user";
import { Chart } from "react-google-charts";

const years = [];
let minOffset = 0, maxOffset = 100;
let currentYear = (new Date()).getFullYear();
for (let x = 0; x <= maxOffset; x++) {
    years.push(currentYear - x)
}
const Dashboard = (props) => {
    const history = useHistory();
    const [dashboardData, setDashboardData] = useState({});
    const [allUserActiveDeactiveGraphData, setAllUserActiveDeactiveGraphData] = useState({});
    const [allMonthsGraphData, setAllMonthsGraphData] = useState({});
    const [loading, setLoading] = useState(true);
    const [year, setYear] = useState(years[0]);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "year") {
            setYear(value);
            getDashboardData(value)
        }
    };

    const options = {
        title: "New User Registered per Month",
        hAxis: { title: "Months", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0 },
        chartArea: { width: "50%", height: "70%" }
    };

    const options2 = {
        title: "Total-users"
    };

    const getDashboardData = (year) => {
        let params = {
            token: props.auth.token,
            year: year ? year : years[0]
        }
        setLoading(true);
        props.getDashboardData(params, res => {
            setLoading(false);
            if (res.status) {
                if (res.data)
                    setDashboardData(res.data);
                let allUserActiveDeactiveGraphData = res.data.user_active_and_deactive_graph_data;
                allUserActiveDeactiveGraphData = allUserActiveDeactiveGraphData.map((item) => {
                    return [item.Task, item.Hours_per_Day];
                });
                allUserActiveDeactiveGraphData.unshift(["Task", "Hours per Day"]);
                setAllUserActiveDeactiveGraphData(allUserActiveDeactiveGraphData)
                let allMonthsGraphData = res.data.month_and_year_wise_count_user_graph_data;
                allMonthsGraphData = allMonthsGraphData.map(obj => {
                    switch (obj.month) {
                        case 1:
                            return { ...obj, month: 'Jan' }
                        case 2:
                            return { ...obj, month: 'Feb' };
                        case 3:
                            return { ...obj, month: 'Mar' };
                        case 4:
                            return { ...obj, month: 'Apr' };
                        case 5:
                            return { ...obj, month: 'May' };
                        case 6:
                            return { ...obj, month: 'Jun' };
                        case 7:
                            return { ...obj, month: 'Jul' };
                        case 8:
                            return { ...obj, month: 'Aug' };
                        case 9:
                            return { ...obj, month: 'Sept' };
                        case 10:
                            return { ...obj, month: 'Oct' };
                        case 11:
                            return { ...obj, month: 'Nov' };
                        case 12:
                            return { ...obj, month: 'Dec' };
                        default:
                            return obj;
                    }
                    // if (obj.month === 1) {
                    //     return { ...obj, month: 'January' };
                    // }
                    // else if (obj.month === 2) {
                    //     return { ...obj, month: 'February' };
                    // }
                    // else if (obj.month === 3) {
                    //     return { ...obj, month: 'March' };
                    // }
                    // else if (obj.month === 4) {
                    //     return { ...obj, month: 'April' };
                    // }
                    // else if (obj.month === 5) {
                    //     return { ...obj, month: 'May' };
                    // }
                    // else if (obj.month === 6) {
                    //     return { ...obj, month: 'June' };
                    // }
                    // else if (obj.month === 7) {
                    //     return { ...obj, month: 'July' };
                    // }
                    // else if (obj.month === 8) {
                    //     return { ...obj, month: 'August' };
                    // }
                    // else if (obj.month === 9) {
                    //     return { ...obj, month: 'September' };
                    // }
                    // else if (obj.month === 10) {
                    //     return { ...obj, month: 'October' };
                    // }
                    // else if (obj.month === 11) {
                    //     return { ...obj, month: 'November' };
                    // }
                    // else if (obj.month === 12) {
                    //     return { ...obj, month: 'December' };
                    // }
                    // return obj;
                });
                allMonthsGraphData = allMonthsGraphData.map((item) => {
                    return [item.month, item.Add, item.Left];
                });

                allMonthsGraphData.unshift(["Months", "Add", "Left"]);
                setAllMonthsGraphData(allMonthsGraphData)

            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error('Something went wrong!');
            });
    }

    useEffect(() => {
        document.title = "Dashboard";
        getDashboardData();
    }, [])

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    {loading ?
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                        :

                        <>
                            <div className="row">
                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>{dashboardData.tottle_user_count ? dashboardData.tottle_user_count : 0}</h3>

                                            <p>Total Users</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-bag" />
                                        </div>
                                        <a onClick={() => { history.push('/user') }} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>{dashboardData.is_active_user_count ? dashboardData.is_active_user_count : 0}</h3>
                                            <p>Active Users</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-stats-bars" />
                                        </div>
                                        <a onClick={() =>{history.push({pathname: '/user', state: {filter: "Active" }})}}  className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{dashboardData.deactive_user_count ? dashboardData.deactive_user_count : 0}</h3>
                                            <p>In-active Users</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-person-add" />
                                        </div>
                                        <a onClick={() =>{history.push({pathname: '/user', state: {filter: "Inactive" }})}} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12  mb-2">
                                <div className="col-md-6 mt-4 mb-3 selectinput">
                                    <label class="yearlabel p-0 mb-0">Select Year:</label>
                                    <select className="cust_spc" value={year} onChange={(e) => { onChangeInput(e, 'year') }}>
                                        {years.length > 0 && years.map((item, index) => {
                                            return <option value={item} key={index}>{item}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-md-7  mb-2'>
                                        {/* user per month chart */}
                                        <div className='userpermonth'>
                                            <Chart
                                                chartType="Bar"
                                                width="100%"
                                                height="400px"
                                                data={allMonthsGraphData}
                                                options={options}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-5'>
                                        {/* user active inactive */}
                                        <div className='piechartactiveinactive'>
                                            <Chart
                                                chartType="PieChart"
                                                data={allUserActiveDeactiveGraphData}
                                                options={options2}
                                                width={"00%"}
                                                height={"400px"}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    }
                    {/* /.row (main row) */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getDashboardData: bindActionCreators(getDashboardData, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);