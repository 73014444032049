import React, { useEffect } from 'react'
// import * as Images from "../../../utilities/images";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "PrivacyPolicy";
    }, []);

    return (
        <>
           <section className='privacyPloicy'>
            <div className='container'>
                <h1 className='my-3'><strong>Privacy Policy</strong></h1>
                <p className='smallText'>We reserve the right to change this Privacy Policy at any time.  In the event we make changes to this Privacy Policy, such policy will be re-posted in the “Privacy” section of our Site with the date such modifications were made indicated on the top of the page or we may contact you to inform you about the revisions with a link to the revised Privacy Policy.  All changes are effective on the date listed at the top of this page and will apply to all information that we have about you.  Your continued use of the Site or the services that we provide (“Services”) after any change is posted indicates your acceptance of the changes and your continued consent to our processing of your personal data.  If at any point you do not agree to any portion of this Privacy Policy then in effect you should immediately stop using the Site and the Services. Because this Privacy Policy contains legal obligations we encourage you to review this Privacy Policy carefully.  If you have any questions concerning this Privacy Policy or our information collection, storage and disclosure practices, please contact us at info@astartingpoint.com or mail us at A Starting Point, 1626 Wilcox Avenue, Suite 104, Los Angeles, California 90028-6206.</p>
                <h6 className='subHeading'>Personal Information That We Collect and How We Use It</h6>
                <p className='smallText'>A Starting Point collects personal data about you when you visit the Site.  “Personal Information” is information that may be used to directly or indirectly identify you, including your name, email address, physical address, phone number or other information about you. The majority of such Personal Information is collected when you register with the Site, use our Services, subscribe to updates, sign up for our mailing list or otherwise provide us with your contact information.  Some of this Personal Information will be required if you wish to partake in the Services that we provide.</p>
                <p className='smallText'>In general, the type of Personal Information we collect includes, but is not limited to:</p>
                <ul>
                    <li className='smallText'>Personal Information to contact you to promote our Services or alert you to features or matters of interest;</li>
                    <li className='smallText'>Personal Information necessary to use the Services that you have requested, including establishing an account;</li>
                    <li className='smallText'>Un-identifiable and aggregated Personal Information pertaining to your Site visits that help us maintain the appropriate features, functionality and user experience</li>
                </ul>
                <ul className='privacylist'>
                    <p className='smallText mb-3'>We and our service providers use that data to:</p>
                    <li className='smallText'>enable you to log in to the Site;</li>
                    <li className='smallText'>enable you to contact your government representatives;</li>
                    <li className='smallText'>guard against potential fraud;</li>
                    <li className='smallText'>contact you if there is a problem with your account;</li>
                    <li className='smallText'> provide answers to your inquiries or questions; and</li>
                    <li className='smallText'>maintain regular communication with you as may be necessary to inform you of offers, updates and other information regarding A Starting Point and its Services.</li>
                </ul>
                <h6 className='subHeading'>Social Networks</h6>
                <p className='smallText'>If you choose to connect to our Site through a third-party social network such as Facebook, Twitter, Instagram and other networks (each, a “Social Network”), we may collect Personal Information from your profile on such Social Network, such as your name, user name, and email address. In addition, our Site offers features that will allow you to share information from our Site to a Social Network or share information from our Social Network sites. If you decide to use such features, they may allow the sharing and collection of information both to and from such Social Network so you should check the privacy policy of each Social Network before using such features.</p>
            </div>
           </section>
        </>
    )
}

export default PrivacyPolicy