import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import moment from 'moment'
import { getUserDetails } from '../../actions/user';

const View = (props) => {
    const [loading, setLoading] = useState(false);
    const [userDetail, setUserDetail] = useState({})


    //get userdetail by ID
    const getUserDetail = (userId) => {
        let params = {
            id: userId,
            token: props.auth.token,
        }

        setLoading(true);
        props.getUserDetails(params, (res) => {
            setLoading(false);
            if (res) {
                setUserDetail(res.data)
            }
            else {
                toast.error(res.message);
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    useEffect(() => {
        getUserDetail(props.selectedUserId);

        return () => {
            setUserDetail({});
        }
    }, [])

    return (
        <>
            <div className="row ps-2 pe-2 pt-3 pb-4 m-0">
                {loading ?
                    <div className="col-12 text-center p-5 mt-5 mb-5">
                        <div className="spinner-grow text-success" role="status"></div>
                    </div>
                    :
                    <>
                    <div className="formDetail_">
                        <div className="row">
                        <div className="col-lg-6">
                            
                            <div className="userDetail_">
                                <label className="label mr-2" for="lfname">Full Name:</label>
                                <span>{userDetail && userDetail.user_profiles ? userDetail.user_profiles.fullname : "N/A"} </span>
                            </div><br />
                            <div className="userDetail_">
                                <label className="label mr-2" for="lfname">Status:</label>
                                <span>{userDetail && userDetail.is_active === true ? "Active" : "In-Active"} </span>
                            </div><br />
                        </div>
                        <div className="col-lg-6">
                            <div className="userDetail_">
                                <label className="label mr-2" for="lfname">Email:</label>
                                <span>{userDetail ? userDetail?.email : "N/A"}</span>
                            </div><br />
                            <div className="userDetail_">
                                <label className="label mr-2" for="lfname">Created At:</label>
                                <span> {moment(userDetail?.created_at ? userDetail?.created_at : "N/A").format("YYYY/MM/DD")}</span>
                            </div><br />
                        </div>
                        </div> </div>
                    </>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getUserDetails: bindActionCreators(getUserDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);