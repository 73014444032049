import { Switch, Route } from 'react-router-dom';
import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>    
      {/* Auth Routes*/}
      <Layouts.NonPrivateRoute exact path="/login" component={Containers.Login} />
      <Layouts.NonPrivateRoute exact path="/forgotPassword" component={Containers.ForgotPassword} />
      <Layouts.NonPrivateRoute exact path="/verify-otp" component={Containers.VerifyOtp} />
      {/* <Layouts.NonPrivateRoute exact path="/resetPassword/:resetPasswordToken" component={Containers.ResetPassword} /> */}
      <Layouts.NonPrivateRoute exact path="/resetPassword" component={Containers.ResetPassword} />
      <Layouts.NonPrivateRoute exact path="/privacypolicy" component={Containers.PrivacyPolicy} />

      <Layouts.PrivateRoute exact path="/" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/questions" component={Containers.AllQuestions} />
      <Layouts.PrivateRoute exact path="/user" component={Containers.User} />
      <Layouts.PrivateRoute exact path="/notes" component={Containers.Notes} />
      <Layouts.PrivateRoute exact path="/settings" component={Containers.AddSettings} />
      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;