import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth'

const Footer = (props) => {
    const history = useHistory();

    const userLogout = () => {
        props.logout();
        history.push('/login');
    }

    return (
        <div>
            {/* <!-- Start Footer Section --> */}
            <footer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul className="footerMenu">
                                {/* <li><a onClick={() => { history.push('/login') }}>Contact Us</a></li>
                                <li><a onClick={() => { history.push('/login') }}>About Us</a></li>
                                <li><a onClick={() => { history.push('/login') }}>Terms of Use</a></li> */}
                                <li><a onClick={() => { history.push('/privacypolicy') }}>Privacy Policy</a></li>
                                <li><span>v1.0 All Rights Reserved</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- End Footer Section --> */}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);