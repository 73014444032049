import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPassword } from "../../actions/user";
import logo from '../../public/images/logo.png';
import email from '../../public/images/email.png';
import password from '../../public/images/password.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;

const ResetPassword = (props) => {
    let { resetPasswordToken } = useParams();
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const[passwordShown, setPasswordShown] = useState(false);
    const [passwordShownConfirmPassword, setPasswordShownConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        newpassword: "",
        confirmpassword: "",
    });

    //onchange input field
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    //Set Customer OTP 
    const onChangeOTP = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    //password field show or hide
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    //password field show or hide
    const togglePasswordVisiblityConfirmpassword = () => {
        setPasswordShownConfirmPassword(passwordShownConfirmPassword ? false : true);
    };

    //form submit on handleSubmit
    const handleSubmit = (e) => {
        e.preventDefault();
        // if (!formData.email) {
        //     toast.error("Please enter email");
        //     return;
        // }
        // else if (!formData.otp) {
        //     toast.error("Please enter otp");
        // }
        if (!formData.newpassword) {
            toast.error("Please enter new password");
            return;
        }
        else if (!formData.confirmpassword) {
            toast.error("Please enter confirm password");
            return;
        }
        setLoading(true);

        var email = localStorage.getItem("email");
        let params = {
            email: email,
            new_password: formData.newpassword,
            confirm_password: formData.confirmpassword
        }

        props.resetPassword(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                history.push('/login');
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error(err.message);
            }
        )


    };

    useEffect(() => {
        document.title = "reset-password";
    }, []);

    return (
        <>
            <section className="adminLogin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 p-0">
                            <div className="loginContent pt-5 ps-3">
                                <div onClick={() => { history.push('/login') }} className="logo">
                                    <img className="img-responsive" src={logo} />
                                </div>
                                <div className="loginForm mt-5">
                                    <form onSubmit={(e) => { handleSubmit(e) }} className="loginHorizontalForm_">
                                        <h3 className="mb-2">Reset Password</h3>
                                        <p className="mb-5">Enter the below details for reset password:</p>
                                        {/* <div className="groupInputs_ mb-4">
                                            <label className="loginIcon">
                                                <img src={email} />
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                placeholder='Email'
                                                value={formData.email}
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                        </div>
                                        <div className=" groupInputs_  mb-4">
                                            <label className="loginIcon">
                                                <img src={password} />
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="otp"
                                                maxLength={6}
                                                placeholder="Enter OTP"
                                                onChange={(e) => { onChangeOTP(e) }}
                                            />
                                        </div> */}
                                        <div className=" groupInputs_  mb-4">
                                            <label className="loginIcon">
                                                <img src={password} />
                                            </label>
                                            <input
                                                className="form-control id_password"
                                                placeholder="New Password"
                                                name="newpassword"
                                                type={passwordShown ? "text" : "password"}
                                                value={formData.newpassword}
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                            <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblity(!passwordShown) }}>
                                                <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                            </span>
                                        </div>
                                        <div className=" groupInputs_  mb-4">
                                            <label className="loginIcon">
                                                <img src={password} />
                                            </label>
                                            <input
                                                className="form-control id_password"
                                                placeholder="Confirm Password"
                                                name="confirmpassword"
                                                type={passwordShownConfirmPassword ? "text" : "password"}
                                                value={formData.confirmpassword}
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                    
                                            {/* <img src={eye_icon} className="icon_eye" alt="eye" /> */}
                                            <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblityConfirmpassword(!passwordShownConfirmPassword) }}>
                                                <span className={passwordShownConfirmPassword ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                            </span>
                                        </div>
                                        <button className="btnTheme" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Continue</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 p-0">
                            <div className="loginImage">
                                <p className="innerContent">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it
                                    look like readable English.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    // userLogin: () => dispatch(userLogin())
    resetPassword: bindActionCreators(resetPassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);