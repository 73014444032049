import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import {getAllQuestions } from '../../actions/allQuestionsAnswer';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import Swal from 'sweetalert2';
import PaginationSetting from '../../constants/pagination';

const AllQuestions = (props) => {

    const [pagination, setPagination] = useState(PaginationSetting)
    const [allQuestions, setAllQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    // get all questions
    const getAllQuestions = () => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }
        setLoading(true)
        props.getAllQuestions(params, (res) => {
            setLoading(false);
            if (res.status) {
                let questions = res.data;
                setAllQuestions(questions);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.total_pages, totalRecords: res.data.total }));
            }
            else {
                setAllQuestions([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    useEffect(() => {
        document.title = "questions";
        getAllQuestions();
    }, [page])

    return (
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-12">
                                <h1 className="m-0">Questions Answer &nbsp;&nbsp;
                                    {/* <button type="button" className="btn btn-primary float-center add-new-btn" 
                                    onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New Care Role' }); setKey(Math.random()); }}
                                    >
                                    <i className="fas fa-plus"></i> Add New</button> */}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content */}
                <section className="content pb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <div className="row p-5 mt-5 mb-5">
                                            <div className="col-12 text-center">
                                                <div className="spinner-grow text-success" role="status"></div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                        <div className='table-responsive'>
                                            <table style={{ width: '100%' }} className="table table-striped questiontable">
                                                <thead style={{ fontSize: '15px' }}>
                                                    <tr>
                                                        <th style={{ borderTop: '0px' }} className='text-center'>Sr. No.</th>
                                                        <th style={{ borderTop: '0px' }}>Questions</th>
                                                        <th style={{ borderTop: '0px' }}>Options</th>
                                                        {/* <th style={{ borderTop: '0px' }}>Action</th> */}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ fontSize: '15px' }}>
                                                    {allQuestions && allQuestions.length > 0 ?
                                                        <>
                                                            {allQuestions.map((question, index) =>
                                                                <tr key={index} >
                                                                    <td className='text-center'>{(page - 1) * pagination.recordsPerPage + index + 1}</td>
                                                                    <td>{question.title}</td>
                                                                    <td key={index}>
                                                                    {question.question_options.map((options, index) =>
                                                                           
                                                                                <li>{options.title}</li>
                                                                              
                                                                    )}
                                                                   </td>
                                                                </tr>
                                                            )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan='4' style={{ textAlign: 'center' }}>No Data Found</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            </div>

                                            {pagination.totalRecords > pagination.recordsPerPage &&
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        key={page}
                                                        count={pagination.totalPages || 0}
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                </Box>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                // child={modalDetail.flag == 'add'
                //     ? <AddCareRole closeModal={() => handleOnCloseModal()} refreshCareRole={() => { getAllQuestions() }} />
                //     : <EditCareRole closeModal={() => handleOnCloseModal()} selectedCareRole={selectedCareRole} refreshCareRole={() => { getAllQuestions() }} />
                // }
                // size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllQuestions: bindActionCreators(getAllQuestions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllQuestions);