import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validatePassword } from "../../actions/user";
import logo from '../../public/images/logo.png';
import email from '../../public/images/email.png';
import password from '../../public/images/password.png';

const VerifyOtp = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [formData, setFormData] = useState({
        email: "",
        otp: "",
    });

    //Set Customer OTP 
    const onChangeOTP = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    //onchange input field
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    //validation form on email password 
    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!formData.email) {
            formIsValid = false;
            errors = { ...errors, email: "Please enter your email." };
        }
        else if (typeof formData.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email.trim())) {
                formIsValid = false;
                errors = { ...errors, email: "Please enter valid email." };
                // toast.error("Please enter valid email");
            }
        }

        setErrorMessages(errors);
        return formIsValid;
    }

    //form submit on handleSubmit
    const handleSubmit = (e) => {
        e.preventDefault();
        // if (!formData.email) {
        //     toast.error("Please enter email");
        //     return;
        // }

         if (!formData.otp) {
            toast.error("Please enter OTP");
            return;
        }
        // if (validateForm()) {
            setLoading(true);
            var email = localStorage.getItem("email");

            let params = {
                type: "email",
                otp: parseInt(formData.otp),
                email: email
            }
            // localStorage.setItem("OTP", parseInt(formData.otp));
            props.validatePassword(params, res => {
                setLoading(false);
                if (res.status) {
                    toast.success(res.message);
                    history.push('/resetPassword');
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        // }

    };

    useEffect(() => {
        document.title = "verify-otp";
    }, []);

    return (
        <>
            {/* <!-- Start Main --> */}
            <section className="adminLogin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 p-0">
                            <div className="loginContent pt-5 ps-3">
                                <div onClick={() => { history.push('/login') }} className="logo">
                                    <img className="img-responsive" src={logo} />
                                </div>
                                <div className="loginForm mt-5">
                                    <form onSubmit={(e) => { handleSubmit(e) }} className="loginHorizontalForm_">
                                        <h3 className="mb-2">Verify OTP</h3>
                                        <p className="mb-5">Enter the below details for forgot password:</p>
                                        {/* <div className="groupInputs_ mb-4">
                                            <label className="loginIcon">
                                                <img src={email} />
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                placeholder='Email'
                                                value={formData.email}
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                        </div> */}
                                        <div className=" groupInputs_  mb-4">
                                            <label className="loginIcon">
                                                <img src={password} />
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="otp"
                                                maxLength={6}
                                                placeholder="Enter OTP"
                                                onChange={(e) => { onChangeOTP(e) }}
                                            />
                                        </div>
                                        <button className="btnTheme" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Continue</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 p-0">
                            <div className="loginImage">
                                <p className="innerContent">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it
                                    look like readable English.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Main --> */}
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    validatePassword: bindActionCreators(validatePassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);