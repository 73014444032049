import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { editSelectedUser } from "../../actions/user";

const EditUser = (props) => {
    const selectedEditUser = props.selectedEditUser;
    const [userEmail, setUserEmail] = useState(selectedEditUser ? selectedEditUser.email : "");
    const [fullname, setFullname] = useState(selectedEditUser && selectedEditUser.user_profiles ? selectedEditUser.user_profiles.fullname : "");
    const [loading, setLoading] = useState(false);


    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName == "fullname") {
            setFullname(value);
        }
        else if (fieldName === "email") {
            setUserEmail(value.trim());
        }

    };

    // //onchange fullname with only enter alpha
    // const handleChange = (e) => {
    //     const { value } = e.target;
    //     const re = /^[A-Za-z]+$/;
    //     if (value === "" || re.test(value)) {
    //         setFullname(value);
    //     }
    // }

    //edit user
    const handleUpdate = () => {
        if (!fullname) {
            toast.error("Please enter full name");
            return;
        }
        else if (fullname.length <= 2) {
            toast.error("Name should be maximum 3 character")
            return;
        }
        else if (!userEmail) {
            toast.error("Please enter email");
            return;
        }
        
        let params = {
            token: props.auth.token,
            fullname: fullname,
            id: selectedEditUser ? selectedEditUser.id : "",
        }

        setLoading(true);
        props.updateUser(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshgetUsers();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })

    };

    useEffect(() => {
        //
    }, [])

    return (
        <>
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                <div className="form-group mt-3 mb-0 col-md-6">
                    <label className="label_text" htmlFor="inputFirstName">Full Name</label>
                    <input min={2}
                        maxLength={50} className="form-control" id="inputName" type="text" value={fullname} placeholder="Full Name"
                        name="fullname" required="" onChange={(e) => { onChangeInput(e, 'fullname') }} />
                </div>
                <div className="form-group mt-3 mb-0 col-md-6">
                    <label className="label_text" htmlFor="email">Email</label>
                    <input className="form-control" id="email" type="text" value={userEmail} placeholder="Enter email"
                        name="email" required="" disabled onChange={(e) => { onChangeInput(e, 'email') }} />
                </div>
                <div className="form-group  col-md-12 mt-3 mb-3">
                    <button className="btn btn-danger" type="button" onClick={props.closeModal} >
                        <span>Close</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={loading} type="button" onClick={() => { handleUpdate() }}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Update</span>
                    </button>
                </div>
            </form>
        </>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateUser: bindActionCreators(editSelectedUser, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
