import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';

const Authenticator = (props) => {
  const history = useHistory();
  const authenticateUser = (isAuthenticationRequired) => {
    console.log(props.auth.token, "props.auth.token");
    if (props.auth && props.auth.isLoggedIn && props.auth.token) {
      // If user are logedin then we can perform any action here

    }
    else if (isAuthenticationRequired) {
      // If user are not login then redirect him to the login page
      history.push('/login');
    }
  }

  useEffect(() => {
    authenticateUser(props.isAuthenticationRequired);
  }, []);

  return (
    <>
        {props.children}
    </>
  )

}

const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator);