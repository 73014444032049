import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL, AUTH_API_URL } from "../../config";
import { logout } from '../../../redux/actions/auth';

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {
  let responseMessage = defaultErrorMessage;
  let payload = response.payload;  // Data returned by API

  if (payload && Array.isArray(payload) && payload.length > 0) {
    responseMessage = payload[0];
  }
  else if (response.msg) {
    responseMessage = response.msg;
  }
  return responseMessage;
}

const getApiResponse = (result, responseMessage) => {
  let res = {};
  if (result.status === 200 || result.status === 201) {
    res = {
      status: true,
      message: responseMessage,
      statusCode: result.status,
      type: message.success,
      data: result.data.payload
    };
  } else {
    res = {
      status: false,
      message: responseMessage,
      type: message.error,
      statusCode: result.status
    };
  }
  return res;
}


 
/****** action creator for add new note ********/
export const addNewNote = (params, cb) => {
  return dispatch => {
      RestClient.post(`${AUTH_API_URL}/notes_combination`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to add note. Please try again later.');   
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
 };
 

/****** action creator for edit new note ********/
export const editSelectedNote = (params, cb) => {
  const id = params.id;
  delete params.id;
  return dispatch => {
    RestClient.put(`${AUTH_API_URL}/notes/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator for get All notes ********/
export const getAllNotes = (params, cb) => {
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/notes`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for change status for notes ********/
export const getNotesChangeStatus = (params, cb) => {
  const id = params.id;
  delete params.id;
  return dispatch => {
    RestClient.put(`${AUTH_API_URL}/notes/status-note/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator for get single note ********/
export const getNoteDetails = (params, cb) => {
  let id = params.id;
  delete params.id;
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/notes/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** action creator for delete note ********/
export const deleteNotes = (params, cb) => {
  const id = params.id;
     delete params.id;
  return dispatch => {
      RestClient.delete(`${AUTH_API_URL}/notes/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to delete notes. Please try again later.');   
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};